.container {
  max-width: 1200px;
  margin: 0 auto;
}
html,
body {
  padding: 0 10px;
  margin: 0;
  font-family: "Garamond", serif;
}
header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
}
header h1 {
  padding: 0;
  margin: 0;
  padding-left: 20px;
  font-family: "Garamond", serif;
  font-size: 48px;
}
button {
  padding: 6px 15px;
  background: #fff;
  background: #0d5095;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  transform: scale(1);
  cursor: pointer;
}
button:hover {
  background: #1063b8;
}
button.grey {
  background: #b8b8b8;
}
button.grey:hover {
  background: #a3a3a3;
}
button.unfocus {
  background: #d1d1d1;
}
button.unfocus:hover {
  background: #ccc;
}
form {
  margin-bottom: 20px;
}
form input,
form label,
form button {
  display: inline-block;
  margin-bottom: 10px;
}
table {
  width: 100%;
}
table th {
  text-align: left;
}
