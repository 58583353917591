@import "lib/variables"

.container
	max-width: 1200px
	margin: 0 auto

html, body
	padding 0 10px
	margin 0
	font-family: "Garamond", serif

header
	display flex
	align-items: center
	justify-content: center
	border-bottom: 1px solid #ccc
	padding: 20px
	margin-bottom: 20px

	h1
		padding 0
		margin 0
		padding-left: 20px
		font-family: "Garamond", serif
		font-size: 48px

button
	padding: 6px 15px
	background white
	background lighten($dsb-blue, 10%)
	color: white
	border none
	border-radius: 3px
	font-size 14px
	line-height 1
	text-align center
	text-decoration none
	transform scale(1)
	cursor pointer

	&:hover
		background lighten($dsb-blue, 20%)
	&.grey
		background darken(#ccc, 10%)
		&:hover
			background darken(#ccc, 20%)
	&.unfocus
		background lighten(#ccc, 10%)
		&:hover
			background #ccc

form
	margin-bottom: 20px
	input, label, button
		display: inline-block
		margin-bottom: 10px

table
	width 100%
	th
		text-align: left